<template>
  <div class="wrapper">
    <div class="fluid-element">
      <div class="retake-background">
        <section>
          <div class="fluid-element">
            <img
              class="retake-banner"
              src="@/assets/retake-banner.png"
              alt=""
            />
          </div>
          <h1 class="page-title">
            Avaliação da Retoma {{ isLexus ? "Lexus" : "Toyota" }}
          </h1>
          <div class="recovery-box page-subtitle" v-if="recoveryValue">
            <template v-if="recoveryValue != 0">
              A sua retoma foi avaliada em
              <b>{{ formatPriceNumber(recoveryValue, 0, "€") }}</b>
            </template>
            <template v-else>
              A sua retoma encontra-se em <b>análise</b>.
            </template>
          </div>
          <div class="page-subtitle">
            Indique-nos a sua matrícula do seu veículo e inicie a sua avaliação
            de retoma.
          </div>
          <div class="plate-input">
            <input
              type="text"
              :class="{
                'form--error': plateValid != null && !plateValid,
                'form--success': plateValid != null && plateValid,
              }"
              @keyup="validatePlate($event)"
              placeholder="Inserir matrícula (ex.: 00AA00)"
              maxlength="6"
              v-model="plate"
            />
            <button type="button" @click="searchPlate">
              Iniciar Avaliação
            </button>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import { checkPlate } from "@/functions/validation.js";
import { formatPriceNumber } from "@/functions/general.js";

export default {
  name: "Retake",
  data() {
    return {
      dealerNif: this.$store.state.dealerInfo.nif,
      plate: "",
      plateValid: null,
      recoveryValue: null,
    };
  },
  mounted() {
    // Check if there is a value saved in session storage
    const recoveryValue = this.$route.query.recovery;
    if (recoveryValue) {
      // Assign the saved value to the component's data property
      sessionStorage.setItem("recovery", recoveryValue);
      this.recoveryValue = recoveryValue;
    } else {
      this.recoveryValue = sessionStorage.getItem("recovery");
    }
  },
  methods: {
    formatPriceNumber,
    validatePlate() {
      this.plateValid = checkPlate(this.plate);
    },
    searchPlate() {
      console.log(process.env);
      if (this.plate.length == 6 && checkPlate(this.plate))
        location.href =
          process.env.VUE_APP_RETOMA_DOMAIN +
          "campanhas/" +
          this.plate +
          "?ref=" +
          location.origin +
          location.pathname +
          "&dealerNif=" +
          this.dealerNif +
          "&isCampaigns=false";
      else {
        this.plateValid = false;
      }
    },
  },
  computed: {
    isLexus() {
      return this.$store.state.isLexus;
    },
  },
};
</script>

<style lang="scss">
@import "../components/Forms/Form.scss";
.recovery-box {
  background-color: $primaryWhiteColor;
  padding: 16px 24px;
  border: 1px solid $primaryGreenColor;
  margin-bottom: 2.8rem !important;
}
</style>
